<template>
	<div class="pui-form-layout">
		<v-row dense class="mt-4">
			<v-col cols="12">
				<pui-field-set :title="$t('form.mooring.serviceinfo')">
					<v-row dense>
						<v-col cols="6">
							<pui-select
								:id="`portname-${modelName}`"
								:attach="`portname-${modelName}`"
								:label="this.$t('form.mooring.portname')"
								toplabel
								clearable
								required
								:disabled="!isCreatingElement || disableserviceInfo"
								v-model="parentObject"
								modelName="port"
								:itemsToSelect="itemsToSelectPort"
								:modelFormMapping="{ id: 'portid' }"
								:key="'portid_' + portidKey"
								itemValue="id"
								itemText="portname"
								reactive
								:fixedFilter="filterByUserPortsAndAuthority"
							></pui-select>
						</v-col>
						<v-col cols="6">
							<pui-select
								:id="`statusname-${modelName}`"
								:attach="`statusname-${modelName}`"
								:label="this.$t('form.mooring.statusname')"
								toplabel
								clearable
								:disabled="true"
								v-model="parentObject"
								modelName="technicalnauticalservicestatuspa"
								:itemsToSelect="[{ statusid: this.parentObject.statusid }]"
								:modelFormMapping="{ statusid: 'statusid' }"
								itemValue="statusid"
								itemText="statusname"
								reactive
								:fixedFilter="filterByAuthority"
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<pui-select
								:id="`visitid-${modelName}`"
								:attach="`visitid-${modelName}`"
								:label="$t('form.mooring.portcallnumber')"
								toplabel
								clearable
								required
								:disabled="!parentObject.portid || !isCreatingElement || disableserviceInfo"
								v-model="parentObject"
								modelName="visit"
								:itemsToSelect="itemsToSelectVisits"
								:modelFormMapping="{ id: 'visitid' }"
								:itemValue="['id']"
								:itemText="
									(item) =>
										`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
											item.eta,
											'DD/MM/YYYY HH:mm'
										)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.etd, 'DD/MM/YYYY HH:mm')} - ${item.status}`
								"
								:fixedFilter="portidFixedFilter"
								:key="'visitid_' + portidKey"
								reactive
								detailComponentName="visit-form"
								detailModelName="visit"
							></pui-select>
						</v-col>
						<v-col cols="6" v-if="!parentObject.outofberth">
							<pui-select
								:id="`stopid-${modelName}`"
								:attach="`stopid-${modelName}`"
								:label="$t('form.mooring.stopnum')"
								toplabel
								clearable
								:disabled="!parentObject.visitid || !isCreatingElement"
								v-model="parentObject"
								modelName="stop"
								:itemsToSelect="itemsToSelectStops"
								:modelFormMapping="{ id: 'stopid' }"
								itemValue="id"
								:itemText="(item) => getStopItemText(item)"
								:order="{ num: 'asc' }"
								:fixedFilter="visitidFixedFilter"
								:key="'stopid_' + visitidKey"
								reactive
								required
								detailComponentName="stop-form"
								detailModelName="stop"
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<pui-checkbox
								:id="`outofberth-${modelName}`"
								:label="$t('form.mooring.outofberth')"
								v-model="parentObject.outofberth"
								:disabled="formDisabled || disableForm"
							></pui-checkbox>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="6">
				<pui-field-set :title="$t('form.mooring.reqdate')">
					<v-row dense>
						<v-col cols="6">
							<pui-date-field
								:id="`startdatereq-mooring`"
								v-model="parentObject.startdatereq"
								:label="$t('form.mooring.startdatereq')"
								:disabled="formDisabled || parentObject.statusid !== 1"
								toplabel
								clearable
								time
								:min="visiteta"
								:max="parentObject.enddatereq ? parentObject.enddatereq : visitetd"
							></pui-date-field>
						</v-col>
						<v-col cols="6">
							<pui-date-field
								:id="`enddatereq-mooring`"
								v-model="parentObject.enddatereq"
								:label="$t('form.mooring.enddatereq')"
								:disabled="formDisabled || parentObject.statusid !== 1"
								toplabel
								clearable
								time
								:min="parentObject.startdatereq ? parentObject.startdatereq : visiteta"
								:max="visitetd"
							></pui-date-field>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-col>

			<v-col cols="6" v-if="hasServiceStatusId(4)">
				<pui-field-set :title="$t('form.mooring.planneddate')" v-if="hasServiceStatusId(4)">
					<v-row dense>
						<v-col cols="6">
							<pui-date-field
								:id="`startdateplanned-mooring`"
								v-model="parentObject.startdateplanned"
								:label="$t('form.mooring.startdateplanned')"
								:disabled="formDisabled || parentObject.statusid !== 4"
								toplabel
								time
								:max="parentObject.enddateplanned"
							></pui-date-field>
						</v-col>
						<v-col cols="6">
							<pui-date-field
								:id="`enddateplanned-mooring`"
								v-model="parentObject.enddateplanned"
								:label="$t('form.mooring.enddateplanned')"
								:disabled="formDisabled || parentObject.statusid !== 4"
								toplabel
								time
								:min="parentObject.startdateplanned"
							></pui-date-field>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="6" v-if="hasServiceStatusId(5)">
				<pui-field-set :title="$t('form.mooring.estimateddate')">
					<v-row dense>
						<v-col cols="6">
							<pui-date-field
								:id="`startdateestimated-mooring`"
								v-model="parentObject.startdateestimated"
								:label="$t('form.mooring.startdateestimated')"
								:disabled="formDisabled || parentObject.statusid !== 5"
								toplabel
								time
								:max="parentObject.enddateestimated"
							></pui-date-field>
						</v-col>
						<v-col cols="6">
							<pui-date-field
								:id="`enddateestimated-mooring`"
								v-model="parentObject.enddateestimated"
								:label="$t('form.mooring.enddateestimated')"
								:disabled="formDisabled || parentObject.statusid !== 5"
								toplabel
								time
								:min="parentObject.startdateestimated"
							></pui-date-field>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-col>
			<v-col cols="6">
				<pui-field-set :title="$t('form.mooring.actualdate')">
					<v-row dense>
						<v-col cols="6">
							<pui-date-field
								:id="`startdateactual-mooring`"
								v-model="parentObject.startdateactual"
								:label="$t('form.mooring.startdateactual')"
								:disabled="formDisabled || parentObject.statusid !== 2"
								toplabel
								time
								:max="parentObject.enddateactual"
							></pui-date-field>
						</v-col>
						<v-col cols="6">
							<pui-date-field
								:id="`enddateactual-mooring`"
								v-model="parentObject.enddateactual"
								:label="$t('form.mooring.enddateactual')"
								:disabled="formDisabled || parentObject.statusid !== 3"
								toplabel
								time
								:min="parentObject.startdateactual"
							></pui-date-field>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12">
				<pui-field-set :title="$t('form.mooring.maininfo')">
					<v-row dense>
						<v-col cols="6">
							<pui-select
								id="type"
								attach="type"
								:label="$t('form.mooring.type')"
								toplabel
								:disabled="formDisabled || disableForm"
								:itemsToSelect="[{ type: this.parentObject.type }]"
								:modelFormMapping="{ type: 'type' }"
								v-model="parentObject"
								:items="[
									{ type: 'A', name: this.$t('grid.mooring.A') },
									{ type: 'D', name: this.$t('grid.mooring.D') }
								]"
								itemValue="type"
								itemText="name"
								:order="{ name: 'asc' }"
								reactive
								required
							></pui-select>
						</v-col>
						<v-col cols="3">
							<pui-number-field
								:id="`numropes-mooring`"
								v-model="parentObject.numropes"
								:label="$t('form.mooring.numropes')"
								:disabled="formDisabled || disableForm"
								toplabel
							></pui-number-field>
						</v-col>
						<v-col cols="3">
							<pui-number-field
								:id="`nummoorers-mooring`"
								v-model="parentObject.nummoorers"
								:label="$t('form.mooring.nummoorers')"
								:disabled="formDisabled || disableForm"
								toplabel
								maxlength="10"
							></pui-number-field>
						</v-col>
					</v-row>
					<v-row dense v-if="isCreatingElement && parentObject.outofberth">
						<v-col cols="5">
							<pui-select
								:id="`resourcetypeid-${modelName}`"
								attach="resourcetypeid"
								:label="$t('form.mooring.resourcetypeid')"
								:disabled="formDisabled"
								v-model="parentObject"
								modelName="resourcetype"
								:itemsToSelect="[{ resourcetypeid: parentObject.resourcetypeid }]"
								:modelFormMapping="{ resourcetypeid: 'resourcetypeid' }"
								itemValue="resourcetypeid"
								itemText="name"
								:fixedFilter="filterByEntity"
								toplabel
								reactive
							></pui-select>
						</v-col>
						<v-col cols="5">
							<pui-select
								:id="`resourceid-${modelName}`"
								attach="resourceid"
								:label="$t('form.mooring.resourceid')"
								:disabled="formDisabled || !enableResources || disableForm"
								v-model="parentObject"
								modelName="resources"
								:itemsToSelect="[{ resourceid: parentObject.resourceid }]"
								:modelFormMapping="{ resourceid: 'resourceid' }"
								itemValue="resourceid"
								itemText="description"
								:fixedFilter="resourceTypeFilter"
								toplabel
								reactive
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense v-if="!parentObject.outofberth">
						<v-col cols="6">
							<pui-select
								:id="`berth-mooring`"
								v-model="parentObject"
								:label="$t('form.mooring.berth')"
								:disabled="true"
								modelName="berth"
								toplabel
								:modelFormMapping="{ id: 'berth' }"
								:itemsToSelect="[{ id: parentObject.berthid }]"
								itemValue="id"
								itemText="name"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="3">
							<pui-select
								:id="`bollardini-mooring`"
								v-model="parentObject"
								:label="$t('form.mooring.bollardini')"
								:disabled="formDisabled || disableForm"
								modelName="bollard"
								toplabel
								:modelFormMapping="{ id: 'bollardini' }"
								:itemsToSelect="[{ id: parentObject.bollardini }]"
								itemValue="id"
								itemText="bolcode"
								:key="berthKey"
								:fixedFilter="berthFilter"
								:order="{ orderby: 'asc' }"
								required
							></pui-select>
						</v-col>
						<v-col cols="3">
							<pui-select
								:id="`bollardend-mooring`"
								v-model="parentObject"
								:label="$t('form.mooring.bollardend')"
								:disabled="formDisabled || disableForm"
								modelName="bollard"
								toplabel
								:modelFormMapping="{ id: 'bollardend' }"
								:itemsToSelect="[{ id: parentObject.bollardend }]"
								itemValue="id"
								itemText="bolcode"
								:key="berthKey"
								:fixedFilter="berthFilter"
								:order="{ orderby: 'asc' }"
								required
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense v-if="!isCreatingElement && parentObject.statusid === 6">
						<v-col cols="6">
							<pui-select
								:id="`cancelationreason-${modelName}`"
								:attach="`cancelationreason-${modelName}`"
								v-model="parentObject"
								:label="$t('form.mooring.cancelationreason')"
								toplabel
								clearable
								disabled
								modelName="cancelationreason"
								:itemsToSelect="[{ id: parentObject.cancelationreasonid }]"
								:modelFormMapping="{ id: 'cancelationreasonid' }"
								itemValue="id"
								itemText="description"
								reactive
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-text-area
								:id="`comments-mooring`"
								v-model="parentObject.comments"
								:label="$t('form.mooring.comments')"
								:disabled="formDisabled || disableForm"
								toplabel
								maxlength="300"
							></pui-text-area>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'mooring-form-main-tab',
	data() {
		return {
			userPortAuthority: window.localStorage.getItem('workingPortAuthorityId'),
			berthKey: 0,
			berthFilter: null,
			portidKey: 0,
			visitidKey: 0,
			visitidFixedFilter: null,
			visiteta: null,
			visitetd: null,
			statusAp: [],
			enableResources: false,
			resourceTypeFilter: null,
			entityTypeFilter: null,
			disableForm: null,
			disableserviceInfo: false
		};
	},
	props: {
		modelName: {
			type: String,
			required: true
		},
		parentObject: {
			type: Object,
			required: true
		},
		modelPk: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(
				startdate,
				'DD/MM/YYYY HH:mm'
			)} - ${this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm')}
			- ${item.status} - ${item.berthnowname}`;
		},
		updateBollardFixedFilter() {
			const opts = {
				model: 'stop',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.parentObject.stopid
						},
						{
							field: 'visit_id',
							op: 'eq',
							data: this.parentObject.visitid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				var location;
				if (response.data.data[0]) {
					if (
						response.data.data[0].statusid == 'IN' ||
						response.data.data[0].statusid == 'FI' ||
						response.data.data[0].statusid == 'IV' ||
						response.data.data[0].statusid == 'IG'
					) {
						location = response.data.data[0].berthactual;
					} else if (
						response.data.data[0].statusid == 'AU' ||
						response.data.data[0].statusid == 'CO' ||
						response.data.data[0].statusid == 'WA'
					) {
						location = response.data.data[0].berthauth;
					} else {
						location = response.data.data[0].locationid;
					}

					this.berthFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'berth_id', op: 'eq', data: location }]
					};

					this.parentObject.berthid = location;
				}
			});
		},
		updateValidations(visitid) {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.parentObject.visitid ? this.parentObject.visitid : visitid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					this.visiteta = response.data.data[0].eta;
					this.visitetd = response.data.data[0].etd;
				}
			});
		},
		hasServiceStatusId(statusid) {
			return this.statusAp.includes(statusid);
		},
		getResources() {
			this.resourceTypeFilter = {
				groupOp: 'and',
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'disableddate', op: 'nu', data: null },
							{ field: 'disableddate', op: 'ge', data: this.parentObject.enddatereq }
						]
					},
					{
						rules: [{ field: 'resourcetypeid', op: 'eq', data: this.parentObject.resourcetypeid }]
					}
				]
			};
		}
	},
	computed: {
		itemsToSelectPort() {
			return [{ id: this.parentObject.portid }];
		},
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: this.userPortAuthority }
				]
			};
		},
		filterByAuthority() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portauthorityid', op: 'eq', data: this.userPortAuthority }]
			};
		},
		itemsToSelectVisits() {
			return [{ id: this.parentObject.visitid }];
		},
		itemsToSelectStops() {
			return [{ id: this.parentObject.stopid }];
		},
		visitStatusCodes() {
			return this.$store.getters.getActiveVisitStatusid;
		},
		stopStatusCodes() {
			return this.$store.getters.getActiveStopStatusid;
		},
		filterByEntity() {
			const nauticalServiceEntity = 'S';
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'associatedentities', op: 'cn', data: nauticalServiceEntity }]
			};
		},
		portidFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portid', op: 'eq', data: this.parentObject.portid },
					{ field: 'statusid', op: 'in', data: this.visitStatusCodes }
				]
			};
		}
	},
	watch: {
		'parentObject.portid'(newVal) {
			if (!newVal) {
				this.visitidFixedFilter = null;
				this.movementVisitidFixedFilter = null;
				this.parentObject.portid = null;
				this.parentObject.stopid = null;
				this.parentObject.movementid = null;
			}
			this.portidKey += 1;
		},
		'parentObject.visitid'(newVal) {
			if (newVal) {
				this.visitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'visitid', op: 'eq', data: newVal },
						{ field: 'statusid', op: 'in', data: this.stopStatusCodes }
					]
				};
			} else {
				this.visitidFixedFilter = null;
				this.parentObject.stopid = null;
			}
			this.visitidKey += 1;
			this.updateValidations();
		},
		'parentObject.stopid'(newVal) {
			if (newVal) {
				this.updateBollardFixedFilter();
			} else {
				this.berthFilter = null;
				this.parentObject.berthid = null;
				this.parentObject.bollardini = null;
				this.parentObject.bollardend = null;
			}
			this.berthKey += 1;
		},
		'parentObject.resourcetypeid'(newVal) {
			if (newVal !== null) {
				if (this.parentObject.startdatereq && this.parentObject.enddatereq) {
					this.getResources();
					this.enableResources = true;
				} else {
					this.enableResources = false;
				}
			} else {
				this.enableResources = false;
			}
		},
		'parentObject.startdatereq'(newVal) {
			if (newVal !== null) {
				if (this.parentObject.enddatereq && this.parentObject.resourcetypeid) {
					this.getResources();
					this.enableResources = true;
				} else {
					this.enableResources = false;
				}
			} else {
				this.enableResources = false;
			}
		},
		'parentObject.enddatereq'(newVal) {
			if (newVal !== null) {
				if (this.parentObject.startdatereq && this.parentObject.resourcetypeid) {
					this.getResources();
					this.enableResources = true;
				} else {
					this.enableResources = false;
				}
			} else {
				this.enableResources = false;
			}
		},
		'parentObject.outofberth'(newVal) {
			if (newVal) {
				this.parentObject.stopid = null;
				this.parentObject.berthid = null;
				this.parentObject.bollardini = null;
				this.parentObject.bollardend = null;
			}
		}
	},
	created() {},

	mounted() {
		if (!this.isCreatingElement) {
			//Get mooring data stop
			const opts = {
				model: 'mooring',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'service_id',
							op: 'eq',
							data: this.modelPk.serviceid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0].locationid) {
					this.berthFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'berth_id', op: 'eq', data: response.data.data[0].locationid }]
					};
					this.parentObject.berthid = response.data.data[0].locationid;
					//Get mooring data visit
					this.updateValidations(response.data.data[0].visitid);
				}
			});

			if (this.parentObject.statusid) {
				this.disableForm = this.parentObject.statusid === 6 ? true : false;
			}
		} else {
			this.parentObject.statusid = 1;
			if (this.$route.params.visitpk) {
				const currentPath = this.$router.currentRoute.path.split('/');
				this.parentModelName = currentPath[1];
				if (this.parentModelName.includes('visit')) {
					this.disableserviceInfo = true;
					this.parentObject.visitid = JSON.parse(atob(this.$route.params.visitpk)).id;
					const opts = {
						model: 'visit',
						filter: {
							groupOp: 'and',
							groups: [],
							rules: [
								{
									field: 'id',
									op: 'eq',
									data: this.parentObject.visitid
								}
							]
						}
					};
					this.$puiRequests.postRequest('/puisearch', opts, (response) => {
						if (response.data.data[0]) {
							this.parentObject.portid = response.data.data[0].portid;
						}
					});
				}
			}
		}
		const opts2 = {
			model: 'technicalnauticalservicestatuspa',
			filter: {
				groupOp: 'and',
				groups: [],
				rules: [
					{
						field: 'port_authority_id',
						op: 'eq',
						data: window.localStorage.getItem('workingPortAuthorityId')
					}
				]
			}
		};
		this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
			if (response.data) {
				//Get towage data visit
				this.statusAp = response.data.data.map((sap) => {
					return sap.statusid;
				});
			}
		});
	}
};
</script>
